@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Against';
  src: url("./fonts/against.otf") format("opentype");
}

@font-face {
  font-family: 'Gyahegi';
  src: url("./fonts/Gyahegi.otf") format("opentype");
}

@layer base {
  .font-against{
    font-family: 'Against';
    src: url("./fonts/against.otf") format("opentype");
    }
  .font-gyahegi{
    font-family: 'Gyahegi';
    src: url("./fonts/Gyahegi.otf") format("opentype");
  }
  .h1 {
    @apply  text-[25px] lg:text-[45px] xl:text-[60px] font-normal capitalize leading-[120%] tracking-[-0.05em] mb-5 font-against;
  }
  .btn {
    @apply py-[18px] px-[50px] h-[66px] rounded-lg flex items-center justify-center text-base uppercase font-semibold bg-black text-white;
  }
}